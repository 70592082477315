%error-message {
    overflow: hidden;
    display: block;
    color: color(red);
    font-size: .85em;
    line-height: 1.44;
    text-align: right;
    transition: opacity .3s 0s ease-in-out, height .3s .3s ease-in-out;
}

/**
 * Default input styling
 *
 * [1] Remove IE clear icon 'X'
 */
%input-base {
    &::placeholder {
        color: color(black);
        opacity: .5;
        font: {
            size: rem(14);
            weight: 300;
            style: italic;
        }
        text-transform: none;
    }

    display: inline-block;
    padding: 12px 15px;
    // height: 44px;
    border: 0;
    //border-bottom: 2px solid color(black);
    outline: none;
    -webkit-appearance: none;
    will-change: background-color;
    font-size: 1rem;


    &:active,
    &:focus {
        outline: none;
    }

    // [1]
    &::-ms-clear {
        display: none;
    }
}

%input-border-bottom {
  border-bottom: 2px dashed color(black, $alpha: .7);
  background-color: transparent;
  color: color(black);

  &:focus,
  &:active {
    border-color: color(black);
  }
}

%input-border {
  border: 1px solid color(grey, 300);
  background-color: color(white);
  color: color(grey, 400);
  border-radius: $border-radius;

  &.ng-invalid:not(.ng-pristine) {
      @extend %input-error-color;
  }
  &:focus,
  &:active {
      border-color: color(grey);
      color: color(grey);
  }
}

%input-checkradio-base {
  + label {
    position: relative;
    cursor: pointer;
    display: inline-block;

    .icon-base,
    .icon-checked {
      position: absolute;
      top: 4px;
      left: 0;
      display: block;
      transition: opacity .1s ease-in-out;
    }

    .icon-checked {
      opacity: 0;
    }


  }


  &.ng-invalid:not(.ng-pristine) + label,
  &.is--error + label {
    .icon-base {
      color: color(red);
    }
  }

  &.ng-invalid:not(.ng-pristine) ~ .form__error-message {
    display: block;
  }
}


/*
 * Default input error colours
 */
 %input-error-color {
  border-color: color(red);
}

/*
 * Input hidden placeholder
 */
%input-hidden {
    display: none;
}
