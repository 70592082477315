/**
 * switch layout select
 * @note for styling selectboxes see /modules/form/inputs/_select.scss
 * @note[1,2]: icon lower z-index for clickable selectbox
 * @file[1, 2]: extends/_inputs.scss
 */
.select {
    @extend %input-base; //[1]
    @extend %input-border;

    position: relative;
    padding: 0;
    min-width: 70px;
    font-weight: 700;
    fill: color(grey, 400);

    select {
        z-index: z-index(button + 1); //[1]
        position: relative;
        padding: cp(.5) 44px cp(.5) cp(.5);
        width: 100%;
        font-weight: 700;
    }

    .icon {
        z-index: z-index(button); //[2]
        position: absolute;
        top: 50%;
        right: rem(16);
        margin-top: -5px;
        width: 12px;
        height: 12px;
        fill: inherit;
    }
}
