.icon {
  position: relative;
  display: inline-block;

  &-svg {
    @include abs(0,0,0,0);
    width: 100%;
    height: 100%;
    fill: inherit;
  }
}
