
button,
input[type=submit] {
  @extend %button-reset;
}

a.button {
  display: inline-block;
}

.button {
  position: relative;
  text-decoration: none;
  border-radius: $border-radius;
  overflow: hidden;
  height: auto;
  font-family: $body-font;
  font-weight: 700;
  padding: cp(.5) cp(1);
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  border: 1px solid;
  transform-origin: center center;
  min-width: rem(130);

  &::before {
    @include abs(0,0,0,0);
    background-color: color(white);
    opacity: .15;
    transition: transform .2s ease-in-out;
    transform: scale(0);
    // filter: blur(5px);
    transform-origin: center center;
    content: '';
    border-radius: 100%;
  }

  &:hover {
    &::before {

      transform: scale(2);
    }
  }

  &:active {
    transform: scale(.98);
  }
}

.button.type--icon {
  .icon {
    width: 18px;
    height: 18px;
    vertical-align: middle;
    margin-left:1ex;
    margin-right: -1ex;
  }
}

.button.type--icon-left {
  .icon {
    width: 18px;
    height: 18px;
    vertical-align: middle;
    margin-right:.5ex;
  }
}

.button.type--icon-right {
  .icon {
    width: 18px;
    height: 18px;
    vertical-align: middle;
    margin-left:.5ex;
  }
}

.button-icon {
  width: 44px;
  height: 44px;
  position: relative;
  display: block;
  transition: transform .2s ease-in-out;
  transform-origin: center center;

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    transform: scale(1.06);
  }
}

.type--fill-pink {
  background-color: color(pink);
  border-color: color(pink);
  color: color(white);
  fill: color(white);
}

.type--fill-purple {
  background-color: color(purple);
  border-color: color(purple);
  color: color(white);
  fill: color(white);
}

.type--fill-blue {
  background-color: color(blue);
  border-color: color(blue);
  color: color(white);
  fill: color(white);
}

.type--stroke-pink {
  border-color: color(pink);
  color: color(pink);
  fill: color(pink);
}

.type--stroke-purple {
  border-color: color(purple);
  color: color(purple);
  fill: color(purple);
}
.type--stroke-blue {
  border-color: color(blue);
  color: color(blue);
  fill: color(blue);
}

.type--stroke-grey {
  border-color: color(grey, 400);
  color: color(grey, 400);
  fill: color(grey,400);
}

.type--stroke-light-grey {
  border-color: color(grey, 300);
  color: color(grey, 350);
  fill: color(grey,350);
}

.type--transparent {
  border-color: transparent;
  padding: cp(.5) 0;
}
