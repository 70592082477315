.reference {
  .teaser {

    .teaser__content__meta {
      // display: none;
    }

    &:hover {
      .teaser__crop::after {
        display: none;
      }
    }
  }
}
