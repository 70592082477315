
@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i,900|Libre+Baskerville:700');

$body-font: 'Lato', sans-serif;
$header-font: 'Libre Baskerville', serif;


$font-size-small: rem(14);
$font-size-base: rem(16);
$font-size-bold: rem(18);

$font-bold: 700;

$font: $body-font;
