.container {
  margin: 0 auto;
  position: relative;
  z-index: 5;
  max-width: $container-width;
  width: 100%; //for flexbox

  &.type--l { max-width: $site-width; }

  &.set--top-margin {
    margin-top: cm(1);
  }
}

@include mappy-query(mobile) {
  .container {
    padding: 0 cm(.5);

    &.set--top-margin {
      margin-top: cm(.5);
    }
  }
}

@include mappy-bp(max-width 1025px) {
  .container {
    padding: 0 cp(.5);
  }
}

@include mappy-bp(min-width 1325px) {
  .container {
    padding: 0 cp(1);

  }
}

@include mappy-bp(min-width 750px max-width 1250px ) {
  .container.type--content {
    max-width: 750px;
  }
}

@include mappy-bp(min-width 1250px) {
  .container {
    &.type--content {
      padding: 0 rem(300);
      max-width: 1360px;
    }
  }
}

