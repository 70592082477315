.map {
  &__info-window {
    // min-width: 420px;
    font-family: $font;

  }

}

app-map {
 .si-content-wrapper {
    padding: 0;
  }

  .si-close-button {
    opacity: 0;
  }

  .si-has-border .si-content-wrapper{
    border: 0;
  }

  .si-shadow-frame {
    box-shadow: 0 9px 16px 0 rgba(0,0,0,0.14);
  }

  .map__info-window {
    display: flex;

    &__crop {
      @extend %aspect-ratio;
      width: 120px;

      img {
        @extend %aspect-ratio-element;
      }
    }

    &__content {
      padding: cp(.5);
      flex: 1;
    }

    &__title {
      color: color(pink);
      margin-bottom: 1ex;
      font-size: 1em;
    }

    &__text {
      opacity: .8;
      font-size: .9em;
      margin-bottom: 1ex;
      display:flex;
      flex-direction: column;

       .type--huur {
        color: color(purple);
      }

      .type--belegging {
        color: color(blue);
      }

      .type--koop {
        color: color(pink);
      }
    }

    .button {
      font-size:.8em;
      padding: 7px 10px;
    }
  }
}
