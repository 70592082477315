/**
 * The form labels
 * @Note[1]: Create radio button styling
 * @Note[2]: Create checkbox styling
 */

 .form label {
  display: inline-block;
  position: relative; //font-weight: 600;
  line-height: 1.2;
  font-size: $font-size-small; // font-weight: 700;
  cursor: pointer;
}

label:has(input.ng-invalid) {
  color: color(red);
}

/**
 * All labels with a required class get a `*` to indicate they are required
 */

.form label.required::after {
  color: color(orange);
  vertical-align: text-top;
  content: ' *';
  &.error {
    color: color(red);
  }
}


/* [1] */

input[type='radio'],
input[type='checkbox'] {
  +label {
    position: relative;
    margin-right: rem(10);
    font-weight: normal;
    font-size: rem(15);
    cursor: pointer;
    padding-left: rem(40);
    padding-top: 2px;
    +.form-error {
      text-align: left;
    }

    .icon {
      @include abs(50%, null, null, 7px);

      transform: translate(-50%, -50%);
      opacity: 0;
      fill: color(black);
      transition: opacity $animation-properties;
    }
  }
  +label::before {
    position: absolute;
    text-align: center;
    top: 0%;
    content: '';
    left: 0;
    width: rem(22);
    height: rem(22);
    background-color: color(white);
    border: 1px solid color(grey,300);
  }

  &:checked+label {
    .icon {opacity: 1; fill: color(white);}
    &::before {
      border-color: color(pink);
      background-color: color(pink, $alpha:1);
      transition: border-color $animation-properties;
    }
  }
}

input[type='radio'].error+label,
input[type='checkbox'].error+label,
input[type='checkbox'].ng-invalid.ng-touched+label,
input[type='checkbox'].ng-invalid.ng-touched~span {
  color: color(red);
  &::before {
    border-color: color(red);
  }
}

/**
 * [1]
 * Label next to a radio button
 */

input[type='radio'] {
  +label::before {
    border-radius: 50%;
    content: '';
  }
  &:checked+label::before {
    content: "\e94c";
  }
}

/**
 * [2]
 * Label next to a *checked* radio button
 */

input[type='checkbox'] {
  +label {
    .icon {
      @include size(12px, 12px);
      margin-left: 5px;
      margin-top: 2px;
    }

    &::before {
      border-radius: 3px;
    }
  }
}
