/**
 * Flexbox grid
 * @param {number} $gutter - unitless size between the columns
 * @param {string} $selector - selector off the children elements (as string, '.CLASSNAME', [data attr]),
 * @param {number} $column-align - property value of justify content,
 * @param {number} $columns-desktop - How many columns needed on desktop,
 * @param {number} $columns-tablet - How many columns needed on tablet,
 * @param {number} $columns-mobile - How many columns needed on mobile,
 * @param {number} $columns-mobile-landscape - How many columns needed on mobile but with an orientation of landscape
 */

/**
 * Default variable settings
 */
 $flex-group-columns-selector: '.grid__item' !default;
 $flex-group-columns-gutter: cm(1) !default;
 $flex-group-columns-desktop: 3 !default;
 $flex-group-columns-tablet: 1 !default;
 $flex-group-columns-mobile: 1 !default;
 $flex-group-columns-mobile-landscape: 2 !default;
 $flex-group-columns-align: flex-start !default;
 $flex-group-column-margin-bottom-fix: 1.2rem !default;
 $flex-group-column-mobile-margin-bottom-fix: 1rem !default;

 /**
  * Flex-group mixin
  * [1] if column align value is space-around there is not left of right margin needed
  * [2] @content directive included
  * [3] Interpolate children selector for customization
  */
 @mixin flex-group(
     $selector: $flex-group-columns-selector,
     $gutter: $flex-group-columns-gutter,
     $column-align: $flex-group-columns-align,
     $columns-desktop: $flex-group-columns-desktop,
     $columns-tablet: $flex-group-columns-tablet,
     $columns-mobile: $flex-group-columns-mobile,
     $columns-mobile-landscape: $flex-group-columns-mobile-landscape,
     $column-margin-bottom-fix: $flex-group-column-margin-bottom-fix,
     $column-mobile-margin-bottom-fix: $flex-group-column-mobile-margin-bottom-fix
 ) {
     $margin-amount: (100 / $gutter);
     $margin: ($margin-amount / 2);
     $basis-desktop: (100% / $columns-desktop);
     $basis-tablet: (100% / $columns-tablet);
     $basis-mobile: 100%;
     $basis-mobile-landscape: 100%;
     $column-margin: 0 ($gutter / 2) $column-margin-bottom-fix;
     $column-mobile-margin-bottom: $column-mobile-margin-bottom-fix;

     display: flex;
     flex-wrap: wrap;
     align-content: stretch;
     justify-content: $column-align;
     width: auto;

     // [1]
     @if $column-align == space-around {
         //$column-margin: 0 auto $margin-amount;
     }

     // [2]
     @content;

     // [3]
     #{$selector} {
         display: flex;

         @include mappy-bp(medium) {
             margin: $column-margin;
             width: calc(#{$basis-desktop} - #{$gutter});
         }
         @include mappy-query(tablet) {
             margin: $column-margin;
             width: calc(#{$basis-tablet} - #{$gutter});;
         }

         @include mappy-query(mobile) {
             margin-bottom: $column-mobile-margin-bottom;
             width: $basis-mobile;
         }
     }

     /*
      * @note -flex-group... is fix for margin-bottom block
      */
     @include mappy-bp(small) {
         overflow: hidden;
         margin-right: -#{($gutter / 2)};
         margin-left: -#{($gutter / 2)};
     }
 }
