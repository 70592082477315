.building {
  &__description__text {
    @extend %rte;
  }

  &__header {

    &__title {
      margin-bottom: 0;
    }

    &__meta {
      color: color(grey, 350);
      display: flex;
      justify-content: center;

      &__item {

        position: relative;
        &:first-child {
          margin-left: 0;

          &::before {
            display: none;
          }
        }

        &::before {
          @include size(8px);
          content: '';
          transform: translateY(-50%);
          background-color: $border-color;
          border-radius: 2px;

        }
      }
    }
  }



  &__gallery {
    position: relative;
    display: block;
    z-index: z-index(nav)-1;
  }

  &__buttons {
    margin-bottom: cm(1);
  }

  &__map {
    .map {
      overflow: hidden;
      border-radius: $border-radius;
    }
    google-map {
      height: 450px;
      display: block;
    }
  }

  &__summary {
    position: relative;
    // padding: cp(3) cp(2);

    .block__title {
      @extend %h4;
      margin-bottom: 1ex;
      color: color(black);
    }

    &__list {
      margin-bottom: -#{cm(1.5)};
      white-space: pre-line;

      &__item {
        @extend %rte;
        margin-bottom: cm(1.5);
        padding: 0 cp(.5);

        &__text {

          color: color(grey);

        }
      }
    }
  }

  &__requirements {

    &__list {
      position: relative;
      max-height: 380px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      overflow: hidden;
      transition: max-height .6s cubic-bezier(0, 1, 0, 1);

      &::before {
        @include abs(null, null, 0, 0);
        width: 100%;
        height: 200px;
        content: '';
        transition: opacity .3s ease-in-out;
        background: linear-gradient(to bottom, rgba(#fff, 0) 0%, rgba(#fff, 1) 98%);
      }

      &.set--active {
        max-height: 2000px;
        transition: max-height .6s ease-in;

        &::before {
          opacity: 0;
        }
      }

      &__item {
        flex: 0 0 45%;
        margin-bottom: cm(2);

        article {
          white-space: pre-line;
        }

        .requirement__text {
          @extend %rte;
        }
      }
    }

    .button.type--icon-right {
      color: color(grey, 400);
      fill: color(grey, 400);
      font-size: rem(15);

      .icon {
        transform-origin: center center;
        transition: transform .3s ease-in-out;
        width: 12px;
        height: 12px;
      }

      &.is--active {
        .icon {
          transform: rotate(180deg) translateY(1px);
        }
      }
    }

    .requirement {
      &__title {
        @extend %h5;
        font-family: $body-font;
        margin-bottom: 1ex;
      }
    }
  }

  &__contact {
    border-radius: $border-radius;
    display: block;
  }

  &__related {
    padding: cp(2) 0;

    .teaser__content__title {
      color: color(blue);
    }

    .teaser__crop::after {
      background-color: color(blue);
    }
  }
}

@include mappy-query(mobile) {
  .building {
    width: 100%;
    overflow-x: hidden;
    &__header {
      padding: cp(.5) 0;
      margin-bottom: cm(.5);
      margin-top: cm(.5);

      &__title{
        margin-top: cm(.5);
        margin-bottom: 1ex;
        font-size: rem(24);
      }

      &__meta {
        flex-wrap: wrap;
        max-width: 90vw;
        margin: 0 auto;
      }

      &__meta__item {
        margin-left: 14px;
        &::before {
          @include abs(50%, null, null,-10px);
        }
      }
    }

    &__gallery {
      margin-bottom: cm(1);
      margin-left: 0;
      // margin-right: -#{cm(.5)};
      padding: 0;
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .button {
        width: 45vw;
      }
    }


  }
}

@include mappy-bp(small) {
  .building {
    &__header {
      padding: cp(2) cp(1);


      &__title {
        margin-top: cp(1);
        font-size: rem(36);
      }

      &__meta {
        font-size: rem(18);
        padding: cp(.5) 0;

        &__item {
          margin-left: cp(1.5);

          &::before {
            @include abs(50%, null, null,-#{cp(1)});
          }
        }
      }
    }

    &__buttons {
      .button {
        margin-bottom: 1ex;
        margin-right: 1ex;
      }
    }

    &__contact {
      .contact-card {
        padding: cp(3);
      }
    }
  }
}

@include mappy-bp(min-width 1050px) {
  .building {
    &__buttons {
      @include abs(null, cp(1), null, null);

      width: rem(220);
      display: flex;
      flex-direction: column;

      .button {
        margin-right: 0;
      }
    }
  }
}

@include mappy-bp(min-width 1050px max-width 1250px) {
    .building {
        .container.type--content {
          left: -150px;
        }
    }
    .building__buttons {
      right: -300px;
    }


}

@include mappy-bp(max-width 1250px) {
  .building__summary {
    padding: cp(2);
  }
}

@include mappy-bp(min-width 1250px) {
  .building__contact {
    padding: 0 cp(1);
  }
}

@include mappy-bp(min-width 767px max-width 1050px) {
  .building__buttons {
    display: flex;
    align-items: center;
    margin-top: -#{cm(1)};
    .button {
      margin: 0 cm(.5) 0 0;
    }
  }
}
