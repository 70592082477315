.topbar__nav {
  position: relative;
  display: flex;
  align-items: center;

  &__list {
    display: flex;
    color: color(grey, 700);

    .set--fixed & {
      color: color(white);
    }

    &__item {
      margin-left: cm(1);

      &:first-child {
        margin-left: 0;
      }

      &.is--current {
        color: color(purple);

        .set--fixed & {
          color: color(purple);
        }
      }

      &__link {
        color: inherit;
        display: block;
        padding: cp(.5) cp(.5);
        overflow: hidden;

        @include mappy-bp(small ) {
        .is--current &,
          &:hover {
            span {
              transform: translate3d(0,-120%, 0);
            }
          }
        }

        span {
          transition: transform .18s ease-in-out;
          position: relative;
          display: inline-block;
          font-weight: 700;

          &::before {
            transform: translate3d(0,0, 0);
            position: absolute;
            top: 120%;
            content: attr(data-hover);
            font-weight: 900;
          }

        }
      }

    }
  }

  &__toggle-button {
    display: none;
    width: 44px;
    height: 40px;

    @include mappy-query(mobile) {
      display: block;
      margin-left: -6px;
    }

    .line {
      width: 70%;
      height: 2px;
      background-color: color(purple);
      display: block;
      margin: 8px auto;
      transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;

      .set--fixed & {
        background-color: color(white);
      }
    }

    &.is--active {
      transition: transform 0.3s ease-in-out;
      transition-delay: 0.4s;
      transform: rotate(45deg);

      .line:nth-child(1),
      .line:nth-child(3) {
        transition-delay: 0.3s;
      }

      .line:nth-child(1) {
        transform: translateY(10px);
      }

      .line:nth-child(2) {
        width: 0px;
      }

      .line:nth-child(3) {
        transform: translateY(-10px) rotate(90deg);
      }
    }
  }

  &__call-button {
    span {
      transition: transform .15s .2s ease-in-out;
      position: relative;
      display: block;
      text-align: center;

      &::before {
        transform: translate3d(0,0, 0);
        position: absolute;
        top: 120%;
        content: attr(data-hover);
        font-weight: 700;
        left: -13px;
        right: -13px;
      }
    }

    &:hover  span,
    &.is--active span {
      transform: translate3d(0,-120%, 0);

    }

    .set--fixed & {
    }
  }
}

@include mappy-query(mobile) {
  .topbar__nav {

    &__toggle-button .line {
      .main-nav--active & {
        background-color: color(white);
      }
    }


    &__list {
      @include fxd(75px, null, null, -100vw);
      width: 100vw;
      height: calc(100vh - 75px);
      flex-direction: column;
      background-color: color(pink);
      color: color(white);
      z-index: z-index(nav);
      justify-content: center;
      align-items: center;
      transform: translate3d(-100vw,0,0);
      transition: left .01s .3s ease-in-out, transform .3s ease-in-out;

      &__item {
        font-size: rem(24);
        margin-right: 0;
        margin-left: 0;
        margin-bottom: cm(1);
        width: 100%;
        text-align: center;

        &.is--current {
          color: color(white);
        }
      }

      .main-nav--active & {
        left: 0;
        transform: translate3d(0,0,0);
        transition: left .01s ease-in-out, transform .3s .3s ease-in-out;
      }
    }



    &__call-button {
      width: 280px;
    }
  }
}
