@mixin mobile-scrollable(
    $selector: '.item',
    $gutter: 20
) {
    overflow: hidden;
    overflow-x: auto;
    display: flex;
    margin-right: -#{$gutter};
    white-space: nowrap;
    flex: 1;
    -webkit-overflow-scrolling: touch;

    #{$selector} {
        display: block;

        &:not(:last-child) {
            margin-right: #{$gutter/2}px;
        }

        &:last-child::after {
            @include pseudo($width: $gutter);
        }
    }
}
