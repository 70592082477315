.search-form {
  position: relative;
  min-width: 200px;

  &__input {
    width: 100%;
    padding-right: 80px;
    height: 52px;

    .type--small & {
      padding-right: 44px;
      height: auto;
    }
  }

  &__button {
    @include abs(50%, 5px, null, null);

    transform: translateY(-50%);

    &:active {
      transform: translateY(-50%) scale(.95);
    }

    .type--small & {
      width: 44px;
      height: 44px;
      right: 0;
      min-width: 10px;
      background-color: transparent;
      border: 0;
      fill: color(grey, 350);

      .icon {
        @include abs(50%, null, null, 50%);

        transform: translate(-50%, -50%);
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

@include mappy-query(mobile) {
  .search-form {
    &__button {
      width: 25px;
      min-width: 10px;
      text-indent:-200px;
    }

    .button .icon {
      @include abs(50%, null, null, 50%);
      transform: translate(-50%, -50%);
      margin-left: 0;
      margin-right: 0;
    }
  }
}
