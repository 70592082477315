.teaser-icon {
  font-size: $font-size-base;

  &__icon {
    display: block;
    margin-bottom: cm(1);

    .icon {
      width: rem(70);
      height: rem(70);
      display: block;
      margin: 0 auto;
    }
  }

  &__content__title {
    @extend %h4;
    color: color(grey, 700);
    margin-bottom: cm(1);
  }
}

@include mappy-query(mobile) {
  .teaser-icon {
    display: flex;

    &__content {
      padding-left: cm(.75);

      &__title {
        margin-bottom: cm(.3);
      }
    }

    &__icon {
      padding-left: cm(.3);
      .icon {

        width: rem(55);
        height: rem(55);
      }
    }
  }
}

@include mappy-bp(small) {
  .teaser-icon {
    &__content {
      text-align: center;
    }
  }
}
