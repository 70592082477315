.footer {
  font-size: $font-size-small;
  color: color(grey, 400);
  margin-top: 2.625em;   // geef footer een margin top mee

  a {
    color: color(blue);

    &:hover {
      color: lighten(color(blue), 7%);
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    padding-top: cp(2);
    padding-bottom: cp(2);
    position: relative;

    //border-top fix
    &::before {
      @include abs(0, cm(1), null, cm(1));
      height: 1px;
      border-top: 1px solid $border-color;
      content: '';
    }
  }

  &__column {
    &__title {
      @extend %secondary-heading;
      @extend %h6;
        // font-size: $font-size-base;
        color: darken(color(grey, 400), 15%);
        padding-bottom: .25em;
    }

    li {
      margin-bottom: .5ex;
    }
  }

  &__column__info {
    display: flex;
    flex-direction: column;
  }

  &__social__icons {
    display: flex;
    flex-direction: row;

    &__item {
      // margin-left: cm(.4);
      fill: color(grey,350);
      &:first-child {
        margin-left: -16px;
      }
    }
  }

  &__logo {
   @extend .topbar__logo;
   .footer & {
     height: rem(78);
     margin-left: 0;
   }
  }

  &__certificates {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-height: 50px;
      max-width: 180px;
      margin-left: cm(1);

      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

@include mappy-query(mobile) {
  .footer {
    padding-top: 80px;

    .container {
      position: initial;// needed for get logo on top of footer
    }

    &__column__title.type--contact {
      display: none; // hide titel because show logo mullerbog
    }

    &__container {
      flex-direction: column;
      padding-top: 15px;
      padding-bottom: 15px;

      &::before {
        right: 15px;
        left: 15px;
      }
    }

    &__column {
      margin-top: cm(1);
      text-align: center;

      &:first-of-type {
        margin-top: 0;
      }
    }

    &__social__icons {
      justify-content: center;
    }

    &__certificates {
      img {
        margin-left: 15px;
        max-width: 120px;
        max-height: 40px;
      }
    }

    &__logo {
      .footer & {
        margin-left: 0;
        position: absolute;
        left: 50%;
        top: 15px;
        transform: translateX(-50%);
      }
    }
  }
}
