.text-center {
  text-align: center;
}

/**
 * Element is hidden
 */
 .is--hidden {
  display: none !important;
}

.is--invisible {
  opacity: 0;
}

.is--visible {
  opacity: 1;
}

.is--fixed {
  position: fixed;
}

.is--clickable {
  cursor: pointer;
}

.disable-scrolling {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}

/**
* Helpers
**/
.pre-line {
    white-space: pre-line;
}

.no--underline {
  &, &:hover, &:focus, &:active {
    text-decoration: none;
  }
}
