.teaser-card {
  display: flex;
  position: relative;
  flex-direction: column;
  font-size: $font-size-base;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &__crop {
    margin-bottom: cm(.5);

    &::before {
      padding-top: 70%;
    }
  }

  img {
    @extend %aspect-ratio-element;
  }

  &__content {
    padding: 0 $border-radius;
    &__title {
      @extend %secondary-heading;

      margin-bottom: 0;
      line-height: normal;
      font-size: rem(18);
      color: color(pink);
    }

    &__text {
      color: color(grey, 400);
      margin-bottom: cm(.3);
    }

    &__meta {
      display: flex;
      flex-direction: column;
      font-size: rem(14);

      &__item {
        white-space: pre;

        &.type--price {
          font-weight: bold;
          margin-right: cm(.25);
        }

        &.type--surfaceSize {
          color: color(grey, 400);
        }

        &.type--huur,
        &.type--belegging,
        &.type--huurkoop,
        &.type--koop {
          @include abs(cm(.25), null, null, cm(.25));
          display: block;
          height: auto;
          text-align: center;
          width: auto;
          font-size: rem(12);
          color: color(white);
          border-radius: $border-radius;
          padding: cp(.1) rem(10);

          div {
            position: relative;
            z-index: 2;
          }
        }

        &.type--huur {
          background-color: color(purple);
        }

        &.type--belegging {
          background-color: color(blue);
        }

        &.type--koop {
          background-color: color(pink);
        }

        &.type--huurkoop {
          // position: relative;
          background: color(purple);
          overflow: hidden;

          &::before {
              @include abs(0,-3%, null, null);
              height: 100%;
              width: 54%;
              border-left: 1px solid white;
              display: block;
              content: '';
              transform: skew(-20deg);
              padding-top: 2px;
              background: color(pink);
              z-index: 1;
          }
        }

        &.type--types {
          width: 100%;
          white-space: normal;
          word-break: break-word;
        }
      }
    }
  }
}
