.teaser {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;

  &:hover {
    .teaser__crop::after {
      transform: scale(2);
      transition: transform .2s  ease-in-out, opacity .2s  ease-in-out;
      opacity: .4;

      .set--unavailable & {
        background-color: color(black);
      }
    }
  }

  &__crop {
    @extend %aspect-ratio;

    border-radius: $border-radius;
    position: relative;
    overflow: hidden;
    border: 1px solid color(grey, 300);

    &::after {
      @include abs(0,0,0,0);
      background-color: color(pink);
      opacity: 0;
      transition: transform .2s .1s ease-in-out, opacity .2s ease-in-out;
      transform: scale(.1);
      // filter: blur(5px);
      transform-origin: center center;
      content: '';
      border-radius: 100%;
    }

    img {
      @extend %aspect-ratio-element;
      max-width: 100%;
      float: left;

      .set--unavailable & {
        filter: saturate(0);
      }
    }
  }

  &__block-link {
    @extend %block-link;
  }

  &__content__meta {
    &__item {
      &.type--unavailable {
        @include abs(50%, null, null, 50%);
        display: none;
        background-color: color(blue);
        color: color(white);
        padding: cp(.2) cp(2);
        text-align: center;
        font-weight: 700;
        font-size: .9em;
        border-radius: 3px;
        // width: 110%;
        transform:rotate(-7deg) translate(-50%, -50%);

        .set--unavailable &  {
          display: block;
        }
      }

      &.type--huur,
      &.type--belegging,
      &.type--koop {
        .set--unavailable & {
          display: none;
        }
      }
    }
  }
}

app-teaser {
  display: block;
  width: 100%;
}
