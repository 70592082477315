.header {
  position: relative;

  &__crop {
    @extend %aspect-ratio;
    border-radius: $border-radius;

    &::before {
      padding-top: 36.25%;
      z-index: 9;
      position: relative;

      opacity: .45;
      // min-height: 330px;


    }

    &.set--overlay::before {
      background-color: color(black);
    }

    img {
      @extend %aspect-ratio-element;
      z-index: 8;
    }

    &__video .html5-video {
      @extend %aspect-ratio-element;
    }
  }

  &__content {
    @extend %aspect-ratio-element;
    z-index: 10;
    padding: cp(2);
    color: color(white);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &__title {
      max-width: rem(800);
    }
  }
}

@include mappy-query(mobile) {
  .header {

    &__crop {
      border-radius: 0;

      &::before {
        padding-top: 56.25%;
      }
    }
  }
}

