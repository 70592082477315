.topbar {
  @include abs(0,null,null,0);

  width: 100%;
  height: rem(95);
  display: flex;
  align-items: center;
  transform: translate3d(0, -100% ,0);
  transition: transform .3s ease-in-out;
  background-color: color(white);
  border-bottom: 1px solid $border-color;

  &.set--fixed {
    position: fixed;
    background-color: color(pink);
    border-bottom-color: transparent;
    color: color(white);
    animation: slide-in .4s ease-in-out;
    box-shadow: 0 0 15px 2px color(black, $alpha:.4);
  }

  .anim--in & {
    transform: translate3d(0,0,0);
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }


  &__logo {
    position: relative;
    width: rem(250);
    height: rem(23);
    text-indent: -100vw;
    font-size: 0px;
    overflow: hidden;
    white-space: nowrap;
    margin: 0;

    svg {
      @include abs(0,null,null,0);
      width: 100%;
      height: 100%;

      &.logo-color {
        display: block;

        .set--fixed &,
        .main-nav--active & {
          display: none;
        }
      }

      &.logo-white {
        display: none;

        .set--fixed &,
        .main-nav--active & {
          display: block;
        }
      }
    }
  }
}

@include mappy-query(mobile) {
  .topbar {
    height: 76px;
    z-index: z-index(nav) + 1;

    .main-nav--active & {
      background-color: color(pink);
      color: color(white);
      border-bottom-color: transparent;
    }

    &__logo {
      width: rem(186);
      height: rem(16);
      order: 2;
      margin-right: auto;
      margin-left: -44px;
    }

    &__nav {
      order: 1;
      margin-left: 0;
      margin-right: auto;
    }

    .container {
      justify-content: center;
    }
  }
}
