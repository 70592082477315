.teaser-list {
  display: flex;
  align-items: center;

  &__crop {
    width: 25%;

    &::before {
      padding-top: 71.5%;
    }
  }

  &__content {
    width: 75%;
    flex-direction: column;
    padding-left: cp(1);
    display: flex;

  }

  &__content__title {
    color: color(pink);
    font-size: rem(22);
    font-family: $body-font;
    margin-bottom: 0;
    margin-bottom: .5ex;
  }

  &__content__text {
    margin-bottom: 0;
  }

  &__content__meta {
    text-align: right;
    font-size: rem(15);
    display: flex;

    &__item {
      font-weight: 700;

      &.type--huur {
        color: color(purple);
      }

      &.type--belegging {
        color: color(blue);
      }

      &.type--koop {
        color: color(pink);
      }

      &.type--huurkoop {
        color: color(purple);
      }
    }
  }
}

@include mappy-query(mobile) {
  .teaser-list {
    align-items: stretch;

    &__crop {
      width: 45%;

      &::before {
        padding-top: 70%;
      }
    }

    &__content {
      padding-left: cp(.5);
      padding-top: 3px;
      padding-bottom: 3px;
      font-size: .9rem;
      justify-content: space-between;

      &__title {
        font-size: 1rem;
      }

      &__meta {
        text-align: left;
        font-size: .8rem;

        &__item {

          &.type--huur,
          &.type--belegging,
          &.type--huurkoop,
          &.type--koop {
            @include abs(5px, null, null, 5px);
            padding: 2px 5px;
            border-radius: 3px;
            display: block;
            color: color(white);

            div {
              position: relative;
              z-index: 5;
            }
          }

          &.type--huur {
            background-color: color(purple);
          }

          &.type--belegging {
            background-color: color(blue);
          }

          &.type--koop {
            background-color: color(pink);
          }

          &.type--huurkoop {
            // position: relative;
            background: color(purple);
            overflow: hidden;

            &::before {
                @include abs(0,-3%, null, null);
                height: 100%;
                width: 54%;
                border-left: 1px solid white;
                display: block;
                content: '';
                transform: skew(-20deg);
                padding-top: 2px;
                background: color(pink);
                z-index: 1;
            }


          }
        }
      }
    }
  }
}

@include mappy-bp(min-width 766px max-width 1200px) {
  .teaser-list {
    align-items: stretch;

    &__content {
      justify-content: space-between;
      padding: cp(.5) cp(1);

      &__title {
        margin-bottom: 1ex;
      }
      // height: 100%;
    }
  }
}

@include mappy-bp(min-width 1200px) {
  .teaser-list {
    &__crop {
      min-width: 280px;
    }

     &__content {
       flex-direction: row;
       align-items: center;

       &__header,
       &__meta {
        width: 50%;
       }

       &__meta {
        padding-left: cp(2);
        justify-content: center;
        flex-direction: column;
        align-items: flex-end;
       }
     }
  }
}

