.brochure {
  .rte-text {
    strong, b {
      font-weight: 700;
    }

    i,em {
      font-style: italic;
    }

    p,
    ul,
    ol,
    dl,
    table {
      margin-bottom: 2ex;
    }

    ul,
    ol {
      //margin-top: -10px;
      margin-bottom: cm(1);
      margin-left: 2.5ex;

      li {
        margin-bottom: 1ex;
        // padding-left: 0ex;
      }
    }

    ul {
      >li {
        list-style: disc;
      }
    }

    ol {
      counter-reset: li;

      li {
        position: relative;
        list-style-type: none;

        &::before {
          position: absolute;
          top: 1px;
          left: -#{cm(1.5)};
          font-size: em(14);
          font-weight: 700;
          content: counter(li)'.';
          counter-increment: li;
        }
      }
    }
  }
}
