.bg--light-grey {
  background-color: color(grey, 200);
  color: color(grey, 700);
  fill: color(grey, 700);
}

.bg--white {
  background-color: white;
  border-radius: $border-radius;
}

.bg--pink {
  color: color(white);
  fill: color(white);
  background-color: color(pink);
}


