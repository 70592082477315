// keep eye on order of imports

@import 'mappy-breakpoints/mappy-breakpoints';
@import '@angular/cdk/overlay-prebuilt.css';

.ks-modal-gallery-backdrop {
  background: #000 !important;
  ;
  opacity: 0.85 !important;
  ;
}

.ks-modal-gallery-panel {
  z-index: 90000 !important;
}

@import 'base/reset';
@import 'functions/all';
@import 'settings/all';
@import 'mixins/all';
@import 'extends/all';
@import 'base/all';
@import 'components/all';
