$gutter: rem(20);
$mobile-gutter: ($gutter * .4);
$grid-columns: 12;
$site-width: 1380px;
$max-width: rem($site-width);
$max-width-small: rem(620);
$container-width: rem(1054);

// Spacing map ------------------------------------------------------------- //
$size-base: (
    margin: rem(30),
    padding: rem(20),
    header: rem(30)
);
