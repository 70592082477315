.grid-1-4 {
  @include flex-group(
      $columns-desktop:4,
      $columns-tablet:3,
      $gutter: rem(15));
  @include mappy-query(mobile) {
    .grid__item {
      margin-bottom: cm(.5);
      padding-bottom: cm(.5);
      border-bottom: solid 1px $border-color;

      &:last-child {
        border-bottom-color: transparent;
      }
    }
  }
}

.grid-1-3 {
  @include flex-group($columns-desktop:3, $gutter: rem(15));
}

.grid-list {
  .grid__item {
    margin-bottom: cm(.5);
    padding-bottom: cm(.5);
    border-bottom: solid 1px $border-color;

    &:last-child {
      border-bottom-color: transparent;
    }
  }
}
