.teaser-reference {

  &__content__header {
    display: flex;
    flex-direction: column-reverse;
  }

  &__content__title {
    @extend %secondary-heading;
    position: relative;
    font-size: rem(16);
    color: color(grey, 700);
    padding-left: 2.5ex;

    &::before {
      @include abs(50%, null, null, 0);
      width: 1.5ex;
      height: 0;
      border-top: 1px solid;
      content: '';
      transform: translateY(-50%);

    }
  }

  &__content__text {
    font-style: italic;
  }
}

// @include mapp-bp {
//   .teaser-reference {

//   }
// }
