.portfolio {
  justify-content: stretch;

  &__filters {
    width: rem(300);
    background-color: color(grey, 200);
    border-left: solid 1px $border-color;
    padding: cm(1) cp(1);
  }

  &__results {
    flex: 1;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: cm(1);
      padding-bottom: cm(1);
      border-bottom: 1px solid $border-color;

      @include mappy-bp(max-width 1025px) {
        flex-direction: column;
      }

      .block__title {
        margin-bottom: 0;
        font-size: rem(26);

        @include mappy-bp(max-width 1025px) {
          margin: cm(.5) 0;
        }
      }
    }

    &__options {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &__sort {
      margin-right: 2ex;

      .portfolio__results & select {
        padding-top: rem(8);
        padding-bottom: rem(8);
      }
    }

    &__layout {
      .button {
        min-width: 50px;
        font-size: rem(15);
        z-index: 8;

        &.is--active {
          border-color: color(pink);
          border-right-width: 1px;
          fill: color(pink);
          color: color(pink);
          // margin-right: -1px;
          z-index: 10;
        }
      }
    }

    &__list {
      &.card-list {
        @include flex-group(
        $columns-desktop:4,
        $columns-tablet:2,
        $gutter: rem(15));

        @include mappy-bp(medium ml) {
          .grid__item {
            flex-basis: calc(33.33% - 0.9375rem);
          }
        }
      }
    }
  }
}

@include mappy-query(mobile) {
  .portfolio {
    flex-direction: column;
    padding: 0;

    &__header {
      padding: cp(1) cm(.5) cm(.5);
      display: flex;
      align-items: center;
      justify-content: space-between;
      // justify-content: center;

      .button.type--icon-right {
        min-width: 70px;
        padding-left: cp(.5);
        padding-right: cp(.5);

        .icon {
          width: 12px;
          height: 12px;
          transform: rotate(-45deg);
          transform-origin: center center;
          transition: transform .2s ease-in-out;
          vertical-align: initial;
        }

        &.is--active .icon {
          transform: rotate(0);
        }
      }

      &__title {
        margin-bottom: 0;
      }
    }

    &__results {
      .portfolio & {
        padding: cm(.5);
      }

      &__header {
        display: none;
      }
    }
  }
  .portfolio__filters {
    width: 100%;
    z-index: z-index(nav) - 10;
    overflow: hidden;
    max-height: 0;
    padding: 0;
    transition: padding .3s ease-in-out, max-height .6s cubic-bezier(0, 1, 0, 1);

    &.is--mobile-active {
      max-height: 1000px;
      padding: cp(1);
      transition: padding .3s ease-in-out, max-height .6s ease-in;
    }
  }
}

@include mappy-bp(small) {
  .portfolio__header {
    display: none;
  }
}

@include mappy-bp(max-width 1025px) {
  .portfolio {
    padding-left: 0;
  }
  .portfolio__results {
    padding: cm(.5) 0 0 cm(.5);
  }
}

@include mappy-bp(min-width 1025px) {
  .portfolio__results {
    padding: cm(1) 0 0 cm(1);
  }
}
