.block {
  margin-bottom: cm(2.5);

  &__title {
    @extend %h3;
    color: color(pink);
    margin-bottom: cm(1);

    a {
      position: relative;
    }

    a::before {
      @include abs(null, null, 0, 0);
      width: 0;
      border-bottom: 2px solid;
      content: '';
      // opacity: 0;
      transition: width .3s ease-in-out, opacity .3s ease-in-out;
    }

    &:hover {
      a::before {
        width: 100%;
        transition: width .3s .2s ease-in-out, opacity .3s .2s ease-in-out;
       // opacity: 1;
      }
    }

    &.type--secondary {
      font-family: $body-font;
      font-size: rem(26);
      color: inherit;
      margin-bottom: cm(.5);
    }

    &.color--blue {
      color: color(blue);
    }
  }
}

@include mappy-query(mobile) {
  .block {
    margin-bottom: cm(2);

    &.set--padding {
      margin-left: -#{cm(.5)};
      margin-right: -#{cm(.5)};
    }

    &.set--padding {
      padding: cp(1.5) cp(.5);
    }
  }
}

@include mappy-bp(min-width 1250px) {
  .block {
    &.set--min,
    .set--min {
      margin-left: -#{cm(2.5)};
      margin-right: -#{cm(2.5)};
      display: block;
    }

    &.set--padding {
      padding: cm(2.5);
    }
  }
}
