.home {
  &__header {
    display: block;
    position: relative;
    z-index: 5;
    margin-bottom: cm(2.5);
    margin-top: cm(1);

    .header__content__title {
      margin-bottom: rem(80);
    }
  }

  &__search {
    .home & {
      @include abs(50%, null, null, 50%);
      z-index: 10;
      max-width: 500px;
      width: 70%;
      transform: translate(-50%, -50%);
      margin-top: 70px;

      input:active,
      input:focus {
        border-color: color(white);
      }
    }
  }

  &__cards {
    @include mappy-bp(small) {
      .grid__item {
        margin-bottom: 0;
      }
    }
  }

  &__map {

    .columns {
      align-items: center;
    }

    &__list {
      padding-right: cp(2);
      // padding-top: cp(.2);

      &__item {
        a {
          padding: cp(.5) 0;
          display: flex;
          justify-content: space-between;
          font-weight: 700;
          border-bottom: 1px solid $border-color;
        }
        .title {
          font-size: rem(18);
        }
        .total {
          color: color(pink);

          &::after {
            content: 'objecten';
            margin-left: 1ex;
            color: color(grey, 400);
            font-size: rem(15);
            font-weight: 300;
          }
        }
      }
    }

    &__map {
      position: relative;
      border-radius: $border-radius;
      overflow: hidden;
    }
  }

  &__about {
    padding: cp(2) 0;
  }

  &__reference {
    margin-top: cm(1);
    color: color(grey,700);

    &__list__item {
      color: color(grey,500);
      margin-top: cm(.5);
      padding-top: cm(.5);
      padding-right: cm(1);
      border-top: 1px solid $border-color;

      &:first-of-type {
        margin-top: 0;
        padding-top: 0;
        border-top-color: transparent;
      }
    }



  }

  &__contact {
    display: block;
    padding-left: cp(2);

    &__card {
      display: block;
      height: 100%;
    }
  }
}

@include mappy-query(mobile) {
  .home {
    width: 100%;
    overflow: hidden;
    &__header {
      width: auto;
      margin-top: 0;
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: cm(1);

      &__crop {
        border-radius: 0;
      }
    }

    &__search {
      .home & {
      width: 80%;
      }
    }

    &__map {
      &__list {
        padding: 0;
        padding-bottom: cp(2);
      }
    }

    &__contact {
      padding-left: 0;
    }

    &__reference {
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .column-1-2 {
        order: 2;
        margin-top: cm(1);
      }

      &__list {
        @include  mobile-scrollable();
        margin-bottom: 20px;
        padding-right: 20px;

        &__item {
          margin-top:0;
          margin-right: 0;
          padding: 0 25px 0 0;
          border: 0;
          position: relative;

          &::before {
            @include abs(50%, 5px, null, null);

            height: 80px;
            border-right: 1px solid $border-color;
            content: '';
            transform: translateY(-50%);
          }

          &:last-child::before {
            display: none;
          }

          .teaser-reference {
            width: 80vw;
            white-space: normal;
          }
        }
      }
    }
  }
}
