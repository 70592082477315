/**
 * Form textarea
 */
textarea {
    // padding: 0;
    width: 100%;
    min-height: 44px*3;
    line-height: 44px;

    &:focus {
      // background-color: color(black, $alpha: .2);
    }
}
