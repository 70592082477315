.loading {
  @include fxd(0, 0, 0, 0);
  z-index: z-index(nav) + 10;
  background-color: color(white, $alpha: .9);
}

.loading__indicator {
  @include abs(50%, null, null, 50%);

  display: none;
  position: absolute;
  width: 200px;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  // margin-top: 70px;


  &.is--active {
      z-index: 40;
      display: flex;
  }

  &__item {
    display: inline-block;
    width: 32px;
    height: 32px;
    transform-origin: center center;
    // margin-right: 5px;

    .is--active & {
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;

        &.is--bounce1 {
            //-webkit-animation-delay: -0.32s;
            animation-delay: -.32s;
        }

        &.is--bounce2 {
            //-webkit-animation-delay: -0.16s;
            animation-delay: -.16s;
        }
    }
  }
}

@keyframes sk-bouncedelay {
  /* stylelint-disable selector-list-comma-newline-after */

  0%, 80%, 100% {
      /* stylelint-enable selector-list-comma-newline-after */
      transform: scale(0);
  }

  40% {
      transform: scale(1);
  }
}
