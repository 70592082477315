.building-gallery {
  position: relative;
  width: 100%;
  overflow: hidden;
  overflow-x: auto;

  &__crop {
    width: 100%;
    height: 100%;
    border: 3px solid #fff;
    border-width: 0 3px 3px 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .ks-gallery {
    #current-figure {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.31);
      border-radius: $border-radius;

      img {
        // width: 100%;
        height: 70vh;
        max-height: 650px;
        max-width: none;
        // max-height: none;
      }
    }

    .dots-container .inside.dot {
      border-radius: 2px;
      opacity: .8;

      &:focus {
        outline: none;
        box-shadow: 0 0 0px 1px color(blue);
      }

      &.active {
        background-color: color(pink);
      }
    }

    a:focus {
      outline: none;
    }


    .main-image-container {
      .description {
        font-weight: normal;
        font-size: rem(15);
        background-color: transparent;
        bottom: -80px;
      }
    }

    .inside.close-image {
      background: center center no-repeat url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZD0iTTE0Ljc0OSAxMi42MjdsOS41NDYgOS41NDZhMSAxIDAgMCAxIDAgMS40MTVsLS43MDcuNzA3YTEgMSAwIDAgMS0xLjQxNSAwbC05LjU0Ni05LjU0Ni05LjU0NiA5LjU0NmExIDEgMCAwIDEtMS40MTQgMGwtLjcwNy0uNzA3YTEgMSAwIDAgMSAwLTEuNDE1bDkuNTQ2LTkuNTQ2TC45NiAzLjA4MWExIDEgMCAwIDEgMC0xLjQxNEwxLjY2Ny45NmExIDEgMCAwIDEgMS40MTQgMGw5LjU0NiA5LjU0NkwyMi4xNzMuOTZhMSAxIDAgMCAxIDEuNDE1IDBsLjcwNy43MDdhMSAxIDAgMCAxIDAgMS40MTRsLTkuNTQ2IDkuNTQ2eiIgZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIi8+Cjwvc3ZnPgo=');
      background-size: 70%;
    }

    .right-arrow-image,
    .left-arrow-image {
      width: 44px;
      height: 44px;
    }

    .right-arrow-image {
      background: center center no-repeat url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTQiIGhlaWdodD0iNTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGcgZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgIDxwYXRoIGQ9Ik0yNyA1My41QzEyLjM2NCA1My41LjUgNDEuNjM2LjUgMjdTMTIuMzY0LjUgMjcgLjUgNTMuNSAxMi4zNjQgNTMuNSAyNyA0MS42MzYgNTMuNSAyNyA1My41em0wLTFjMTQuMDgzIDAgMjUuNS0xMS40MTcgMjUuNS0yNS41UzQxLjA4MyAxLjUgMjcgMS41IDEuNSAxMi45MTcgMS41IDI3IDEyLjkxNyA1Mi41IDI3IDUyLjV6IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz4KICAgIDxwYXRoIGQ9Ik0yNC44MTIgMzUuMjNsOC40MDgtOC4wOGEuODk1Ljg5NSAwIDAgMCAwLTEuM2wtOC40MDgtOC4wOGEuOTg1Ljk4NSAwIDAgMC0xLjM1NCAwbC0uNjc3LjY1YS44OTUuODk1IDAgMCAwIDAgMS4zMDJsNy4wNTMgNi43NzgtNy4wNTMgNi43NzhhLjg5NS44OTUgMCAwIDAgMCAxLjMwMmwuNjc3LjY1Yy4zNzQuMzYuOTguMzYgMS4zNTQgMHoiLz4KICA8L2c+Cjwvc3ZnPgo=');
      background-size: 100%;
    }

    .left-arrow-image {
      background: center center no-repeat url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTQiIGhlaWdodD0iNTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGcgZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgIDxwYXRoIGQ9Ik0yNyA1My41YzE0LjYzNiAwIDI2LjUtMTEuODY0IDI2LjUtMjYuNVM0MS42MzYuNSAyNyAuNS41IDEyLjM2NC41IDI3IDEyLjM2NCA1My41IDI3IDUzLjV6bTAtMUMxMi45MTcgNTIuNSAxLjUgNDEuMDgzIDEuNSAyN1MxMi45MTcgMS41IDI3IDEuNSA1Mi41IDEyLjkxNyA1Mi41IDI3IDQxLjA4MyA1Mi41IDI3IDUyLjV6IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz4KICAgIDxwYXRoIGQ9Ik0yOS4xODggMzUuMjNsLTguNDA4LTguMDhhLjg5NS44OTUgMCAwIDEgMC0xLjNsOC40MDgtOC4wOGEuOTg1Ljk4NSAwIDAgMSAxLjM1NCAwbC42NzcuNjVhLjg5NS44OTUgMCAwIDEgMCAxLjMwMkwyNC4xNjYgMjYuNWw3LjA1MyA2Ljc3OGEuODk1Ljg5NSAwIDAgMSAwIDEuMzAybC0uNjc3LjY1YS45ODQuOTg0IDAgMCAxLTEuMzU0IDB6Ii8+CiAgPC9nPgo8L3N2Zz4K');
      background-size: 100%;
    }
  }
}

@include mappy-query(mobile) {
  .building-gallery {

    &__list {
      @include mobile-scrollable($selector: '.building-gallery__list__item', $gutter: 4);
      min-width: 100%;
    }

    &__list__item {
      // pointer-events: none;
    }

    &__crop {
      width: 96vw;

    }

    .ks-gallery #current-figure img {
      height: auto;
      width: 100%;

    }

    .ks-gallery {

      .left-sub-container,
      .right-sub-container {
        position: absolute;
        top: 0;
        height: 100%;
        z-index: 10;
        width: 60px;
        padding-bottom: 50px;
        align-items: flex-end !important;

        a {

          >div,
          &:hover,
          &:active {
            transform: scale(1) !important;
          }
        }
      }

      .right-sub-container {
        right: 0;
      }
    }
  }
}

@include mappy-bp(small) {
  .building-gallery {
    &__list {
      display: grid;
      min-width: 100%;
      //grid-template-columns: repeat(auto-fill, minmax(15vw, 1fr));
      grid-template-columns: repeat(auto-fill, minmax(15vw, 1fr));
      //grid-template-rows: repeat(2, 20vh);
      grid-template-rows: repeat(2, 25vh);
      grid-auto-flow: dense;

      &.set--xl {
        grid-template-columns: repeat(auto-fill, minmax(10vw, 1fr));
      }

      &.set--total-1 {
        &:first-of-type {
          grid-area: 1 / 1 / span 2 / span 7;
        }
      }

      &.set--total-1 {
        &:first-of-type {
          grid-area: 1 / 1 / span 2 / span 7;
        }
      }

      &.set--total-3 {
        .building-gallery__list__item {
          &:first-of-type {
            // grid-area: 1 / 1 / span 2 / span 5;
          }

          &:nth-of-type(2) {
            grid-area: span 2 / span 2;
          }
        }
      }

      &__item {
        position: relative;
        overflow: hidden;

        cursor: pointer;

        // padding: 0 3px 3px 0;
        &:first-of-type {
          grid-area: 1 / 1 / span 2 / span 3;
        }

        // &:nth-of-type(2),
        // &:nth-of-type(3) {
        //   grid-area: span 2 / span 2;
        // }

        &::before {
          @include abs(0, 5px, 5px, 0);
          // @include size(100%);
          content: '';
          opacity: 0;
          border-radius: 100%;
          transform: scale(.5);
          background-color: color(pink);
          transition: opacity .2s ease-in-out, transform .2s .2s ease-in-out;
        }

        &:hover {

          .icon {
            transition: opacity .2s .1s ease-in-out, transform .2s .1s ease-in-out;
            opacity: 1;
            transform: scale(1);
          }

          &::before {
            transform: scale(1.5);
            transition: opacity .2s .1s ease-in-out, transform .2s .1s ease-in-out;
            opacity: .4;
          }
        }

        .icon {
          @include abs(50%, null, null, 50%);
          width: 30px;
          height: 30px;
          margin-top: -15px;
          margin-left: -15px;
          transform: scale(0);
          transform-origin: center center;
          fill: color(white);
          opacity: 0;
          transition: opacity .2s ease-in-out, transform .2s ease-in-out;
        }
      }
    }

    [data-total~="4"] {
      .building-gallery__list__item {
        &:nth-of-type(2) {
          grid-area: 1/4 / span 2 / span 2;
        }
      }
    }

    [data-total~="6"] {

      .building-gallery__list__item {

        &:nth-of-type(6) {
          grid-area: 1/7 / span 2 / span 2;
        }
      }
    }
  }


}
