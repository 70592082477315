.building-features {
  position: relative;

  &__list {
    margin-bottom: cm(1);

    &__title {
      font-weight: 700;
      margin-bottom: cp(.5);
    }

    &__definition {
      display: flex;
      justify-content: space-between;
      // padding: cp(.5) 0;
      border-bottom: 1px solid $border-color;

      em {
        color: color(grey, 400);
      }

      span {
        margin-left: 1ex;
      }
    }
  }
}

@include mappy-query(mobile) {
  .building-features {
    &__list__definition {
      padding: cp(.3) 0;
    }
  }
}

@include mappy-bp(small) {
  .building-features {
    &__list__definition {
      padding: cp(.5) 0;
    }
  }
}
