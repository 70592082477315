.button-group {
  display: flex;

  &__item {
    .button {
      border-radius: 0;
      border-right-width: 0;
    }
    &:first-child {

      .button {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }
    }

    &:last-child {
      .button {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        border-right-width: 1px;
      }
    }
  }
}
