.columns {


  @include mappy-bp(small) {
    display: flex;
  }
}

.column-1-2 {

  @include mappy-bp(small) {
    width: 50%;
  }
}

.column-1-3 {

  @include mappy-bp(small) {
   width: 33.3333%;
  }
}

.column-2-3 {

  @include mappy-bp(small) {
    width: 66.6666%;
  }
}

.column-1-4 {

  @include mappy-bp(small) {
    width: 25%;
  }
  // flex: 0 0 25%;
}

.column-3-4 {
  @include mappy-bp(small) {
    width: 75%;
  }
}
