.content {
  &__header {
    margin-bottom: cm(2.5);
    margin-top: cm(1);
    display: block;

    .header__crop {
      &::before {
        padding-top: 35%;

        @include mappy-query(mobile) {
          padding-top: 56.25%;
        }
      }
    }
  }

  &__block__text {
    @extend %rte;
  }

  &__block__text {
    position: relative;
    z-index: 1;
  }

  h2, h1, h3, h4 {
    line-height: 1.5;
  }

  // quick fix
  &[data-target="over"] {
    em {
      @extend %h5;
      display: inline-block;
      // color: darken(color(grey, 300), 20%);
      color: color(pink);
      border-left: 2px solid color(pink);
      font-style: italic;
      padding-left: cp(1);
      margin-top: cm(1);

      .bg--light-grey & {
        color: color(pink);
      }
    }
  }

  &__searching {
    padding: cm(2) cm(3);
    border-radius: $border-radius;
    text-align: center;

    h2 strong {
      display: block;
      margin-bottom: cm(.65);
    }

    @include mappy-query(tablet) {
      padding: cm(2) cm(2.5);
    }

    @include mappy-query(mobile) {
      padding: cm(2) cm(2);
    }

    .block__title {
      margin-bottom: cm(.5);
    }

    em {

      &:before {
        display: inline-block;
        margin: auto;
        border-top: 2px solid color(pink);
        width: 120px;
        max-width: 100%;
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      position: relative;
      border-left: 0;
      padding-top: cp(1);
      padding-left: 0;
    }

    dl {
      & > img {
        display: inline-block;
        margin-bottom: cm(.65);
        max-width: 100%;
        width: 65px;
      }

      & > p {
        margin: 0;
        strong {
          display: inline-block;
          margin-top: cm(.80);
          margin-bottom: cm(.65);
        }
      }
    }
  }

  &__contact {
    padding: cm(2);
    border-radius: $border-radius;
    position: relative;

    &__title {
      @extend %h4;
      margin-bottom: cm(1);
    }

    &__text {
      position: relative;
      z-index: 1;
    }

    &__crop {
      @extend %aspect-ratio;
      @include abs(null, 5px, 0, null);
      width: 330px;
      z-index: 0;
      mix-blend-mode: soft-light;

      &::before {
        padding-top: 70%;
      }


      img {
        @extend %aspect-ratio-element;
        filter: grayscale(100);
      }
    }
  }

  &__title {
    font-weight: $font-bold;
    padding-bottom: cm(.15);
  }

  &__desc {
    padding-bottom: cm(1);

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  &__list {

    &__split {
    column-count: 2;
    column-rule-width: 1px;

      &:last-of-type {
        margin-bottom: cm(1);
      }
    }

    &__item {
      flex: 0 0 50%;
      justify-content: space-between;

      &__bullet {
        flex: 0 0 50%;
        justify-content: space-between;

        &:before {
          content: "•";
          color: color(pink);
          margin-right: .5em
        }
      }
    }
  }
}

@include mappy-query(mobile) {
  .content {

    .header__crop {
      border-radius: 0;
      margin-bottom: cm(.8);
    }

    &__searching {
      padding: cp(2) cp(1);
    }

    &__list__split {
      columns: 1;
    }

    &__contact {
      padding: cp(2) cp(1);

     &__title {
      font-size: rem(24);
     }

     &__crop {
      width: 200px;
      right: 0;
      img {
        left: 50px;
      }
     }
    }
  }
}
