%rte {

  @include mappy-query(mobile) {
    font-size: 15px;
  }

  a {
      font-weight: 600
  }

  iframe,
  object,
  embed {
      width: 100%;
      height: auto;
      min-height: 500px;
  }

  .entry-content img {
      margin: 0 0 24px;
      max-width: 740px;
  }


  p,
  ul,
  ol,
  dl,
  table,
  blockquote {
      @include mappy-query(mobile) {
          margin-bottom: 1ex;
      }

      @include mappy-bp(small) {
          margin-bottom: 2ex;
      }
  }

  // p:last-of-type {
  //     margin-bottom: 0;
  // }

  em,
  i {
      font-style: italic;
  }

  blockquote {
      @extend %h5;
      position: relative;
      margin: 1ex auto;
      max-width: 500px;
      font-weight: 100;
      text-align: center;

      &::before {
          position: absolute;
          top: 0;
          left: 50%;
          font-size: 5em;
          font-weight: 400;
          content: '“';
          transform: translateX(-50%);

          @include mappy-bp(small) {

          }
      }

      @include mappy-query(mobile) {
          padding: 40px cp(1) 0;
      }

      @include mappy-bp(small) {
          padding: 50px cp(1) cp(.5);
      }
  }

  ul,
  ol {
      //margin-top: -10px;
      margin-bottom: cm(1);
      margin-left: cp(1);

      li {
          margin-bottom: 1ex;
          padding-left: cp(.2);
      }
  }

  ul {
      > li {
          list-style: disc;
      }
  }

  ol {
      counter-reset: li;

      li {
          position: relative;
          list-style-type: none;

          &::before {
              position: absolute;
              top: 1px;
              left: -#{cm(1.5)};
              font-size: em(14);
              font-weight: 700;
              content: counter(li)'.';
              counter-increment: li;
          }
      }
  }

  dl {
      dt {
          font-weight: 700;
      }
  }

  ul.results {
      margin: 0;

      > li {
          list-style: none;
      }
  }

  table {
      -webkit-overflow-scrolling: touch;
      overflow: hidden;
      overflow-x: auto;
      width: 100%;
      border: 0;
  }
}
