.form-row {
  display: flex;
  margin-bottom: cm(.4);

  #{$all-text-inputs} {
    width: 100%;
    margin-top: 5px;
    font-size: $font-size-small;
  }

  &.full--width {
    .form-row__field {
      width: 100%;
      padding: 0;
    }
  }

  &__field {
    display: flex;
    flex-direction: column-reverse;
    width: 50%;

    &:nth-of-type(even) {
      padding-left: cp(.5);
    }

    &:nth-of-type(odd) {
      padding-right: cp(.5);
    }

    &.type--end {
      padding-left: cp(1);
      padding-right: 0;
    }
  }

  .form-row__checkfield {
    // display: flex;
    // justify-content: flex-start;
    width: 100%;
    // align-items: center;
    position: relative;
    padding-left: rem(32);
    padding-top: 0px;

    > label {
      @include abs(0,null, null, 0);


    }
  }
}

@include mappy-query(mobile) {
  .form-row {
    display: flex;
    flex-direction: column;
    margin-bottom: cm(0);

    &__field {
      width: 100%;
      padding-bottom: rem(22);

      &:nth-of-type(even) {
        padding-left: 0;
      }

      &:nth-of-type(odd) {
        padding-right: 0;
      }

      &.type--end {
        padding-left: 0;
      }
    }
    .form-row__checkfield {
      padding-left: rem(24);
    }
  }
}

