.filter-bar {
  &__section {
    margin-bottom: cm(.5);
    padding-bottom: cm(.5);
    border-bottom: 1px solid $border-color;

    &:first-of-type,
    &:last-of-type {
      border-bottom: 0;
    }

    &__title {
      @extend %h6;
      margin-bottom: cm(.5);
    }
  }
  &__list {
    li {
      margin-bottom: cm(.3);
    }
  }


}
