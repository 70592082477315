.contact-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: $border-radius;
  height: 100%;
  position: relative;


  &__title {
    @extend %h4;
    margin-bottom: cm(1);
    z-index: 5;
  }

  &__text {
    padding-right: cp(1);
    color: color(grey, 400);
    line-height: 34px;
    font-size: $font-size-bold;
    font-style: italic;
    z-index: 5;
    max-width: 520px;
  }

  &__meta {
    margin-top: cm(1);
    margin-bottom: cm(1);
    font-weight: $font-bold;
    z-index: 5;
  }

  &__buttons {
    .button:first-of-type {
      margin-right: 1ex;
    }
  }

  &__crop {
    @extend %aspect-ratio;
    @include abs(null, 0, 0, null);
    min-width: 180px;
    width: 35%;
    z-index: 0;

    &::before {
      padding-top: 100%;
    }

    img {
      @extend %aspect-ratio-element;
    }
  }

  .call--button {
    position: relative;
    span {
      transition: transform .15s .2s ease-in-out;
      position: relative;
      display: block;
      text-align: center;

      &::before {
        transform: translate3d(0,0, 0);
        position: absolute;
        top: 120%;
        content: attr(data-hover);
        font-weight: 700;
        left: -13px;
        right: -13px;
      }
    }

    &:hover  span,
    &.is--active span {
      transform: translate3d(0,-120%, 0);

    }
  }
}

@include mappy-query(mobile) {
  .contact-card {
    padding: cm(1) cm(.5);
    &__title {
      font-size: rem(24);
    }

    &__text {
      padding: 0;
      padding-bottom: cm(.3);
    }

    &__meta {
      margin: 0;
      margin-bottom: cm(1);
      font-size: rem(15);
    }

    &__crop {
      width: 226px;
      opacity: .2;
    }

    &__buttons {
      display: flex;
      justify-content: space-between;

      .button {
        width: 45%;
      }
    }
  }
}

@include mappy-bp(small) {
  .contact-card {
    padding: cm(1);
  }
}
