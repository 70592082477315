%heading {
    font-family: $header-font;
    margin: 0 0 .3em;
    line-height: 1;
    transition: opacity .3s ease-in;
    margin-bottom: 1ex;
    font-weight: 700;
}

%h1 {
  @include mappy-query(mobile) {
    font-size: rem(28);
    line-height: rem(34);
  }

  @include mappy-bp(small) {
    font-size: rem(46);
    line-height: rem(58);
  }
}

%h2 {
  font-size: rem(36);
}

%h3 {
  @include mappy-query(mobile) {
    font-size: rem(19);
  }

  @include mappy-bp(small) {
    font-size: rem(32);
  }
}

%h4 {
  @include mappy-query(mobile) {
    font-size: rem(15);
  }

  @include mappy-bp(small) {
    font-size: rem(24);
  }
}

%h5 {
  font-size: rem(18);
}

%h6 {
  font-size: rem(16);
}

%secondary-heading {
  font-family: $body-font;
  font-weight: 700;
}


