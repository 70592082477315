.app {
  &__topbar {
    position: relative;
    display: block;
    z-index: z-index(nav);
  }
  &__footer,
  &__router {
    position: relative;
    display: block;
    opacity: 0;
    transition: opacity .3s .5s ease-in-out;

    .anim--in & {
      opacity: 1;
    }
  }

  &__router {
    padding-top: rem(95); //topbar height
  }
}

@include mappy-query(mobile) {
  .app {
    &__router {
      padding-top: 70px;
    }
  }
}
