html,
body {
  width:100%;
  height: 100%;
}


/**
* Body tag styling
*/
body {
  font-family: $body-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  line-height: 1.5;
  overflow-x: hidden;
  font-size: $font-size-base;
  color: color(grey);

  @include mappy-query(mobile) {
    &.main-nav--active .app__wrapper {
      overflow: hidden;
      width: 100vw;
      // height: 100vh;
      position: absolute;
    }
  }
}


/**
* A-tag styling
*/
a {
  color: inherit;
  text-decoration: none;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  @extend %heading;
  color: inherit;
}

.h1 { @extend %h1; }
.h2 { @extend %h2; }
.h3 { @extend %h3; }
.h4 { @extend %h4; }
.h5 { @extend %h5; }
.h6 { @extend %h6; }

p, .p {
  margin: 0 0 1.5em;
  line-height: 1.61;
}

figure {
  margin: 0;
}

img {
  display: inline-block;
}

/**
* Reset strong for bold styling kuch
*/
strong, b {
  font-weight: 700;
}

hr {
  margin: calc-margin(1) 0;
}

/**
* Window.breakpoint
* [1] For mobile
* [2] For tablet
* [3] For desktop
* [4] For ultra desktop
*/

body {
  @include mappy-query(mobile) { @include define-breakpoint('breakpoint-mobile'); }
  @include mappy-query(tablet) { @include define-breakpoint('breakpoint-tablet'); }
  @include mappy-query(desktop) { @include define-breakpoint('breakpoint-desktop'); }
  @include mappy-query(large-desktop) { @include define-breakpoint('breakpoint-large-desktop'); }
}

[hidden] {
display: none;
}

google-map {
  height: 300px;
  display: block;
}
